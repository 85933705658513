import React from "react"
import Helmet from "react-helmet"

// Testing out Fomantic instead of Semantic UI to see if it works any better...
// If there are issues, replace the code returned below with the commented code here.

// return (
//   <Helmet>
//     <link
//       rel="stylesheet"
//       href="https://fonts.googleapis.com/css?family=Libre+Franklin:300,400,600,700,800&display=swap"
//     />
//     <link
//       rel="stylesheet"
//       href="https://cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.4.1/semantic.min.css"
//     />
//   </Helmet>

function SemanticUI() {
  return (
    <Helmet>
      <script src="https://unpkg.com/jquery@3.3.1/dist/jquery.js" />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://unpkg.com/fomantic-ui@2.8.6/dist/semantic.min.css"
      />
      <script src="https://unpkg.com/fomantic-ui@2.8.6/dist/semantic.min.js" />
    </Helmet>
  )
}

export default SemanticUI
