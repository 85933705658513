// import { Link } from "gatsby"
import PropTypes from "prop-types";
import React from "react";
import ImageHeader from "./images/imageHeader";
import ImageHeaderRight from "./images/imageHeaderRight";
import { Link } from "gatsby";
import { Grid } from "semantic-ui-react";

const Header = ({ siteTitle }) => (
  <Grid style={{ paddingBottom: `20px` }} stackable verticalAlign="middle">
    <Grid.Column floated="left" width={5}>
      <Link to="/">
        <ImageHeader />
      </Link>
    </Grid.Column>
    <Grid.Column floated="right" width={5}>
      <ImageHeaderRight />
    </Grid.Column>
  </Grid>
);
// <Img fixed={data.file.childImageSharp.fixed} />
// <img
//   src="https://surveyresna.s3.amazonaws.com/misc_public_resources/pardot/IMI-001_IMI_NextWave_1Colour.png"
//   style={{ width: `400px`, marginLeft: `-1rem` }}
//   alt="IMI NextWave Logo"
// />

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;

// export const query = graphql`
//   query {
//     file(relativePath: { eq: "IMINextWaveHeader.png" }) {
//       childImageSharp {
//         # Specify the image processing specifications right in the query.
//         # Makes it trivial to update as your page's design changes.
//         fluid(maxWidth: 400) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `
