import React from "react";

import { Segment, Grid, Header, List } from "semantic-ui-react";
export function Footer(props) {
  return (
    <Segment>
      <Grid columns={3} divided stackable>
        <Grid.Column>
          <Header as="h5">IMI International</Header>
          <p>
            IMI is a global research and consulting firm focused on Insights
            Driving Profits.
          </p>
        </Grid.Column>
        <Grid.Column>
          <Header as="h5">Network</Header>
          <List>
            <List.Item><a href="https://www.sponsorpulseimi.com">SponsorPulse By IMI</a></List.Item>
            <List.Item><a href="https://www.linkedin.com/company/imi-international">LinkedIn</a></List.Item>
          </List>
        </Grid.Column>
        <Grid.Column>
          <Header as="h6">Get in Touch</Header>
          <List>
            <List.Item>
              <List.Icon name="users" />
              <List.Content>IMI International</List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="marker" />
              <List.Content>Toronto, ON</List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="mail" />
              <List.Content>
                <a href="mailto:info@consultimi.com">info@consultimi.com</a>
              </List.Content>
            </List.Item>
            <List.Item>
              <List.Icon name="linkify" />
              <List.Content>
                <a href="http://www.consultimi.com">consutlimi.com</a>
              </List.Content>
            </List.Item>
          </List>
        </Grid.Column>
      </Grid>
    </Segment>
  );
}
