/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";

import { Container, Segment } from "semantic-ui-react";

import Header from "./header";
import SemanticUI from "./semantic";
import { Footer } from "./Footer";
// import "./layout.css"

const Layout = ({ children, ...props }) => {
  return (
    <>
      <SemanticUI />
      <Container>
        <Segment style={{ margin: `20px 0px` }}>
          <Header />
          <main>{children}</main>
        </Segment>
        <Footer />
        <footer>
          © {new Date().getFullYear()},{` `}
          <a href="https://www.consultimi.com">IMI International</a>
        </footer>
      </Container>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
